export const ETH = '0x0000000000000000000000000000000000000000'

export const ERC20_SYMBOL_BYTES32_ABI = [
  'function symbol() public view returns (bytes32)'
]

export const ERC20_SYMBOL_DECIMALS_ABI = [
  'function decimals() public view returns (uint8)',
  'function symbol() public view returns (string)'
]
